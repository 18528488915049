import { PulseStandardEventTargetType } from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { contactEvent, targetClassifiedAd, type } from './objectHelpers';

/**
 * Event for tracking sending an Email
 * @param id  ID of the ClassifiedAd.
 */
export const trackPulseEmailEvent = (id: string) => {
  const label = 'EmailSeller';
  trackPulseEvent(
    contactEvent(label, {
      id: label,
      ...type(PulseStandardEventTargetType.EmailContact),
      inReplyTo: {
        ...targetClassifiedAd(id),
      },
    })
  );
};
