import { PulseStandardEvent } from './PulseEvent';

export type PulseBaseFunction<TArgs extends Array<any>, TReturn extends any> = (
  ...args: TArgs
) => TReturn;

export type PulseDefaultFunction = PulseBaseFunction<Array<any>, unknown>;

export type PulseTrackingFunction = PulseBaseFunction<
  ['track', string, PulseStandardEvent],
  void
>;

export type PulseFunction = PulseDefaultFunction | PulseTrackingFunction;

export interface PulseWindow {
  pulse: PulseFunction;
}

export const isPulseReady = (window: Window) =>
  typeof window?.pulse === 'function';

declare global {
  interface Window extends PulseWindow {}
}
