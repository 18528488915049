import * as React from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

export const useInViewCallback = (
  elementRef: React.RefObject<HTMLElement>,
  handleInView: () => void,
  intersectionOptions?: IntersectionOptions
) => {
  const [inViewRef, inView] = useInView(intersectionOptions);

  React.useEffect(() => {
    if (elementRef.current) {
      inViewRef(elementRef.current);
    }
  }, [elementRef, inViewRef]);

  React.useEffect(() => {
    if (inView) {
      handleInView();
    }

    // Disable the linter warning to enable running this effect only once when in view:
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps
};
