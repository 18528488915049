import { TrackingPlugin } from './TrackingPlugin';

export type TrackingManagerConfig<
  TEvent extends {},
  TArgs extends Array<any> = []
> = {
  plugins: Array<TrackingPlugin<TEvent, TArgs>>;
};

export class TrackingManager<TEvent extends {}, TArgs extends Array<any> = []> {
  constructor(private config: TrackingManagerConfig<TEvent, TArgs>) {}

  handleEvent(event: TEvent, ...args: TArgs) {
    this.config.plugins.forEach((plugin) => plugin.handleEvent(event, ...args));
  }
}
