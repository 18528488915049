/**
 * A subset of the types defined in the schema.
 * @see https://confluence.schibsted.io/pages/viewpage.action?pageId=173642427
 */
export enum PulseStandardEventObjectType {
  /**
   * An general type to be used for buttons, banners,
   * sections etc. It is the most abstract type.
   */
  UIElement = 'UIElement',

  /**
   * A general type of page, that is not covered by any of the
   * specialized types (ClassifiedAd, Listing, Frontpage).
   */
  Page = 'Page',

  /**
   * A type that describes an Ad. What is known previously as a VIP.
   */
  ClassifiedAd = 'ClassifiedAd',

  /**
   * A type that is meant to contain a List of items. Most common
   * usage is a search result page (SRP) but it can also be seller
   * other items, or list of dealers etc.
   */
  Listing = 'Listing',

  /**
   * Subtype of type page. The frontpage/welcome page of a website.
   */
  Frontpage = 'Frontpage',

  /**
   * A type meant to be used for forms of any kind: finance
   * application form, login form, etc.
   */
  Form = 'Form',

  /**
   * A type meant to be used for items recommended, like Klikxtra
   * cars on bilbasen.
   */
  RecommendationItem = 'RecommendationItem',

  /**
   * A type meant to be used for items displayed on a Listing object.
   */
  ListingItem = 'ListingItem',

  /**
   * A type meant to be used for ads, i.e. Display, AdSense, DBA Shopping, etc.
   */
  DisplayAd = 'DisplayAd',
}
