import { PulseStandardEvent } from '../PulseEvent';
import { isPulseReady } from '../PulseWindow';

/**
 * Generic pulse tracking method
 */
export const trackPulseEvent = (event: PulseStandardEvent) => {
  if (isPulseReady(window)) {
    window.pulse('track', 'trackerEvent', event);
  } else {
    console.warn('Warning: Pulse SDK not loaded.');
  }
};
