/**
 * A subset of the types defined in the schema.
 * @see https://confluence.schibsted.io/pages/viewpage.action?pageId=173642427
 */
export enum PulseStandardEventType {
  /**
   * A page or a visual element is viewed by the user.
   * @example User viewed the frontpage. User viewed a specific banner. User viewed a financing form.
   */
  View = 'View',

  /**
   * User clicks on a visual element.
   * @example User clicks on Login button, or on a cancel button, or save, or bilbasen icon.
   */
  Click = 'Click',

  /**
   * User performs an edit action.
   * @example User edits name text field, or a finance application form.
   */
  Edit = 'Edit',

  /**
   * A visual elemented is loaded.
   * @example Insurance banner is loaded/rendered on bilbasen.
   */
  Load = 'Load',
}
