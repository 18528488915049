/**
 * A subset of the intents defined in the schema.
 * @see https://confluence.schibsted.io/pages/viewpage.action?pageId=173642427
 */
export enum PulseStandardEventIntent {
  /**
   * Used when the action performed has the result of opening an object.
   * @example Click on open finance form. Click to open a ClassifiedAd.
   */
  Open = 'Open',

  /**
   * Used when the action performed has the result of contacting another user.
   * @example User sends SMS or chat message.
   */
  Contact = 'Contact',

  /**
   * Used when the action performed has the result of updating an object.
   * @example Update classified ad, finance form.
   */
  Update = 'Update',

  /**
   * Used when the action performed results in canceling an operation.
   * @example Cancel submitting of a finance form.
   */
  Cancel = 'Cancel',

  /**
   * Used when the action performed results in submitting an object.
   * @example Submit finance form.
   */
  Submit = 'Submit',

  /**
  * Used when the action performed results in sending an object.
  * @example Send dealer email
  */
  Send = 'Send',

  /**
   * Used when the action performed results in sharing an object.
   * @example Sharing a add on facebook.
   */
  Share = 'Share',

  /**
   * Used when the action performed results in publishing an ad for advertising.
   * @example Advertising a classified ad on DBA.
   */
  Publish = 'Publish',

  /**
   * Used when the action performed results in deleting an object.
   * @example Delete a classified ad from DBA.
   */
  Delete = 'Delete',

  /**
   * Used when the action performed results in liking/favouriting an object.
   * @example User clicks heart button on DBA classified ad, to favourite ad.
   */
  Like = 'Like',

  /**
   * Used to revert the Like intent.
   * @example User clicks heart button to revert a favourite .
   */
  Unlike = 'Unlike',
}
