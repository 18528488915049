import * as React from 'react';

export const useRenderCallback = (
  elementRef: React.RefObject<HTMLElement>,
  handleRender: () => void
) => {
  React.useEffect(() => {
    const element = elementRef.current;

    if (element) {
      handleRender();
    }

    // Disable the linter warning to enable running this effect only once:
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
