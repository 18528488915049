import {
  PulseStandardEvent,
  PulseStandardEventIntent,
  PulseStandardEventObjectType,
  PulseStandardEventType,
} from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { getPulseObject, targetClassifiedAd, type } from './objectHelpers';

/**
 * Event for tracking Listing Item Clicks on Searches
 * @param id  ID of the target ClassifiedAd.
 * @param position  The position/index/rank of the item that is clicked.
 * @param clickCount  The incremental number of clicked items within the session.
 */
export const trackPulseListingItemEvent = (
  id: string,
  position?: number,
  clickCount?: number
) => {
  const rank = position ? { rank: position } : {};
  const sptCustom = clickCount
    ? {
        'spt:custom': {
          ClickCount: {
            Value: clickCount.toString(),
          },
        },
      }
    : {};
  const event: PulseStandardEvent = {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Open,
    object: {
      id: id,
      ...type(PulseStandardEventObjectType.ListingItem),
      name: 'SearchResultItem',
      ...rank,
      ...sptCustom,
      page: getPulseObject(),
    },
    target: {
      ...targetClassifiedAd(id),
    },
  };
  trackPulseEvent(event);
};
