import {
  PulseStandardEvent,
  PulseStandardEventIntent,
  PulseStandardEventTargetType,
  PulseStandardEventType,
} from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { eventUIElementObject, type } from './objectHelpers';

/**
 * Event for tracking External Content
 * @param clickOut Specifying whether it is a ClickOutWebsite or ClickOutDeeplink.
 */
export const trackPulseExternalContentEvent = (
  clickOut: 'ClickOutWebsite' | 'ClickOutDeeplink'
) => {
  const event: PulseStandardEvent = {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Open,
    object: eventUIElementObject('ExternalViewItem'),
    target: {
      id: clickOut,
      name: clickOut,
      ...type(PulseStandardEventTargetType.ExternalContent),
    },
  };
  trackPulseEvent(event);
};
