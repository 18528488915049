import { PulseEvent, PulseFormContent, PulseFormField, PulseStandardEventIntent, PulseStandardEventType, PulseUIElementContent } from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { eventUIElementObject, formContent, targetClassifiedAd, type } from './objectHelpers';

/**
 * Event for tracking sending an Email to a dealer
 * @param classifiedAdId  ID of the ClassifiedAd.
 * @param classifiedAdInternalId  The Syi ID of the ClassifiedAd in SDRN format.
 * @param sptCustom  The object of custom data from the email form
 */
export const trackPulseEmailDealerEvent = (
  classifiedAdId: string,
  classifiedAdInternalId: string,
  formData: Array<PulseFormField>) => {
  trackPulseEvent(pulseEmailDealerEvent(classifiedAdId, classifiedAdInternalId, formData));
};

/**
 * Specifying a Email Dealer Event
 * @param classifiedAdId  ID of the ClassifiedAd.
 * @param classifiedAdInternalId  The Syi ID of the ClassifiedAd in SDRN format.
 * @param sptCustom  The object of custom data from the email form
 */
export const pulseEmailDealerEvent = (
  classifiedAdId: string,
  classifiedAdInternalId: string,
  formData: Array<PulseFormField>
): PulseEvent<PulseUIElementContent, PulseFormContent> => {
  const uiElementLabel = 'EmailSeller';
  const formLabel = 'Contact';
  return {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Send,
    object: eventUIElementObject(uiElementLabel),
    target: formContent(formLabel, targetClassifiedAd(classifiedAdId, classifiedAdInternalId), formData)
  };
};
