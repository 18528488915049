import * as React from 'react';
import { TrackingManager } from './TrackingManager';

export type TrackingContextValue<
  TEvent extends {},
  TArgs extends Array<any> = []
> = TrackingManager<TEvent, TArgs> | null;

export const createTrackingContext = <
  TEvent extends {},
  TArgs extends Array<any> = []
>() => React.createContext<TrackingContextValue<TEvent, TArgs>>(null);
