import { PulseStandardEventTargetType } from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { contactEvent, targetClassifiedAd, type } from './objectHelpers';

/**
 * Event for tracking Phone interactions
 * @param id  ID of the ClassifiedAd.
 * @param label  Specifying whether it is a Call or SMS.
 */
export const trackPulsePhoneEvent = (
  id: string,
  label: 'CallSeller' | 'SMSSeller'
) => {
  trackPulseEvent(
    contactEvent(label, {
      id: label,
      ...type(PulseStandardEventTargetType.PhoneContact),
      inReplyTo: {
        ...targetClassifiedAd(id),
      },
    })
  );
};
