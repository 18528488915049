/**
 * A subset of the types defined in the schema.
 * @see https://confluence.schibsted.io/pages/viewpage.action?pageId=173642427
 */
export enum PulseStandardEventTargetType {
  /**
   * Contact info used for emailing.
   */
  EmailContact = 'EmailContact',

  /**
   * Contact info used for calling/SMS.
   */
  PhoneContact = 'PhoneContact',

  /**
   * A type meant to be used for forms of any kind:
   * finance application form, login form, etc.
   */
  Form = 'Form',

  /**
   * A type that describes an Ad. What is known previously as a VIP.
   */
  ClassifiedAd = 'ClassifiedAd',

  /**
   * A type that is meant to display a List of items. Most common
   * usage is a search result page (SRP) but it can also be seller
   * other items, or list of dealers etc.
   */
  Listing = 'Listing',

  /**
   * A type that is meant to be used for conversation, like a chat.
   */
  Conversation = 'Conversation',

  /**
   * A type that is meant to be used when directing users outside
   * our site. Like a finance banner that takes the user to the
   * financial companyes website.
   */
  ExternalContent = 'ExternalContent',
}
