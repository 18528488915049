import {
  PulseStandardEvent,
  PulseStandardEventIntent,
  PulseStandardEventObjectType,
  PulseStandardEventTarget,
  PulseStandardEventTargetType,
  PulseStandardEventType,
  PulseClassifiedAdContent,
  PulseUIElementContent,
  PulseFormContent,
  PulseFormContentRelatedTo,
  PulseFormField
} from '../PulseEvent';

import { PulseObject } from '@smd/datalayer-typings';

/**
 * Insure both @type and type are set.
 */
export const type = <
  TType extends
    | PulseStandardEventType
    | PulseStandardEventObjectType
    | PulseStandardEventTargetType
>(
  type: TType
) => {
  return {
    '@type': type,
    type: type,
  };
};

/**
 * Get the Pulse Page Object safely from the DataLayer.
 * DISCLAIMER: Some Ad Blockers causes the Google Tag Manager (GTM) not to load, and
 * leaving the DataLayer without the 'get' utility functions.
 */
export const getPulseObject = (): PulseObject => {
  return window.dataLayer?.getPulse?.()?.pulse?.object as PulseObject;
};

/**
 * Specifying the UIElement Event Object
 */
export const eventUIElementObject = (label: string): PulseUIElementContent => {
  return {    
      id: label, // This should be the DOM Element ID if we have it.
      ...type(PulseStandardEventObjectType.UIElement),
      name: label,
      page: getPulseObject(),
    }  
};


/**
 * Specifying a minimal ClassifiedAd object
 */
export const targetClassifiedAd = (id: string, internalId?: string): PulseClassifiedAdContent => {
  return {
    id: id,
    ...type(PulseStandardEventTargetType.ClassifiedAd),
    name: '',
    contentId: id,
    ...(internalId && { internalId }),
    category: '',
  };
};

export const formContent = (formId: string, relatedTo?: PulseFormContentRelatedTo, formData?: Array<PulseFormField>): PulseFormContent => {
  return {
    id: formId,
    ...type(PulseStandardEventTargetType.Form),    
    relatedTo: relatedTo,
    fields: formData
  }
}

/**
 * Specifying a Initial Contact Object
 */
export const initialContactEvent = (
  id: string,
  label: string
): PulseStandardEvent => {
  return {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Open,
    object: eventUIElementObject(label),
    target: {
      id: label,
      ...type(PulseStandardEventTargetType.Form),
      name: label,
      relatedTo: targetClassifiedAd(id)      
    },
  };
};

/**
 * Specifying a Contact Object
 */
export const contactEvent = (
  label: string,
  target: PulseStandardEventTarget,
  name?: string
): PulseStandardEvent => {
  return {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Contact,
    name: name,
    object: eventUIElementObject(label),
    target: target,
  };
};
