import * as React from 'react';

export const useClickCallback = (
  elementRef: React.RefObject<HTMLElement>,
  handleClick: (event: MouseEvent) => void
) => {
  React.useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    element.addEventListener('click', handleClick);
    return () => element.removeEventListener('click', handleClick);
  }, [elementRef, handleClick]);
};
