export enum PulseClassifiedAdContentAdType {
  Buy = 'buy',
  Sell = 'sell',
  Rent = 'rent',
  Let = 'let',
  Lease = 'lease',
  Swap = 'swap',
  Give = 'give',
  JobOffer = 'jobOffer',
}
