import {
  PulseStandardEvent,
  PulseStandardEventIntent,
  PulseStandardEventType,
} from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import {
  eventUIElementObject,
  targetClassifiedAd,
  type,
} from './objectHelpers';

/**
 * Event for tracking Favorite interactions
 * @param id  ID of the ClassifiedAd.
 * @param intent  Specifying add or remove favorite mark by Like and Unlike
 */
export const trackPulseFavoriteAdEvent = (
  id: string,
  intent: PulseStandardEventIntent.Like | PulseStandardEventIntent.Unlike
) => {
  const label = 'FavoriteAd';
  const event: PulseStandardEvent = {
    ...type(PulseStandardEventType.Click),
    intent: intent,
    object: eventUIElementObject(label),
    target: {
      ...targetClassifiedAd(id),
    },
  };
  trackPulseEvent(event);
};
