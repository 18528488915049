import { trackPulseEvent } from './trackPulseEvent';
import { initialContactEvent } from './objectHelpers';

/**
 * Event for tracking initial open of or navigation to the Chat Form
 * @param id  ID of the ClassifiedAd.
 */
export const trackPulseOpenConversationEvent = (id: string) => {
  trackPulseEvent(initialContactEvent(id, 'ChatSeller'));
};
