import {
  PulseStandardEvent,
  PulseStandardEventIntent,
  PulseStandardEventType,
} from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import {
  eventUIElementObject,
  targetClassifiedAd,
  type,
} from './objectHelpers';

export type SharingMedia =
  | 'Twitter'
  | 'Pinterest'
  | 'WhatsApp'
  | 'Messenger'
  | 'Facebook'
  | 'Email'
  | 'CopyLink';

/**
 * Event for tracking Social Sharing
 * @param id  ID of the ClassifiedAd.
 * @param media  Specifying sharing media
 */
export const trackPulseSharingEvent = (id: string, media: SharingMedia) => {
  const label = 'ShareAd';
  const event: PulseStandardEvent = {
    ...type(PulseStandardEventType.Click),
    intent: PulseStandardEventIntent.Share,
    name: media,
    object: eventUIElementObject(label),
    target: {
      ...targetClassifiedAd(id),
    },
  };
  trackPulseEvent(event);
};
