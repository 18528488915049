import { PulseStandardEventTargetType } from '../PulseEvent';
import { trackPulseEvent } from './trackPulseEvent';
import { contactEvent, targetClassifiedAd, type } from './objectHelpers';

export type ConversationEntry =
  | 'InitialPrivateChat'
  | 'PrivateChat'
  | 'PublicChat';

/**
 * Event for tracking Chat Messages
 * @param id  ID of the ClassifiedAd.
 * @param conversationEntry  Specifying the conversation entry.
 */
export const trackPulseConversationEvent = (
  id: string,
  conversationEntry: ConversationEntry
) => {
  const label = 'ChatSeller';
  trackPulseEvent(
    contactEvent(
      label,
      {
        id: label,
        ...type(PulseStandardEventTargetType.Conversation),
        inReplyTo: {
          ...targetClassifiedAd(id),
        },
      },
      conversationEntry
    )
  );
};
