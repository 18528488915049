import { trackPulseEvent } from './trackPulseEvent';
import { initialContactEvent } from './objectHelpers';

/**
 * Event for tracking initial open of or navigation to the Email Form
 * @param id  ID of the ClassifiedAd.
 */
export const trackPulseOpenEmailEvent = (id: string) => {
  trackPulseEvent(initialContactEvent(id, 'EmailSeller'));
};
